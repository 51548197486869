import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    pastaId: 0,
    tokenName: 'RCHAP',
    earnToken: 'RCHAP',
    stakingTokenName: QuoteToken.RCHAP,
    stakingTokenAddress: '0x7EcaaCA6eB73beeBCc2595dE864410dC50567B7F',
    contractAddress: {
      97: '',
      355113: '0x30CA643734CEc96F9202931A7b2A16B7c8aC6b85',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'http://chapswap.com/',
    harvest: true,
    tokenPerBlock: '0.005',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  }
]

export default pools
