export type IfoStatus = 'coming_soon' | 'live' | 'finished'

export interface Ifo {
  id: string
  isActive: boolean
  address: string
  name: string
  subTitle?: string
  description?: string
  launchDate: string
  launchTime: string
  saleAmount: string
  raiseAmount: string
  pizzaToBurn: string
  projectSiteUrl: string
  currency: string
  currencyAddress: string
  tokenDecimals: number
  releaseBlockNumber: number
}

export enum QuoteToken {
  'BNB' = 'BNB',
  'PIZZA' = 'PIZZA',
  'CHS' = 'CHS',
  'USDT' = 'USDT',
  'BUSD' = 'BUSD',
  'HOTS' = 'HOTS',
  'KP3RB' = 'KP3RB',
  'PASTA' = 'PASTA',
  'DAI' = 'DAI',
  'ETH' = 'ETH',
  'USDC' = 'USDC',
  'CAKE' = 'CAKE',
  'XCHS' = 'XCHS',
  'tUSDT' = 'tUSDT',
  'INT' = 'INT',
  'COD' = 'COD',
  'BFT' = 'BFT',
  'RCHAP' = 'RCHAP',
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance', // Pools using native BNB behave differently than pools using a token
}

export interface Address {
  97?: string
  355113: string
}

export interface FarmConfig {
  pid: number
  lpSymbol: string
  lpAddresses: Address
  tokenSymbol: string
  tokenAddresses: Address
  tokenDecimal?: number
  quoteTokenSymbol: QuoteToken
  quoteTokenAdresses: Address
  multiplier?: string
  isCommunity?: boolean
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
}

export interface PoolConfig {
  pastaId: number
  image?: string
  tokenName: string
  earnToken: string
  stakingTokenName: QuoteToken
  stakingLimit?: number
  stakingTokenAddress?: string
  contractAddress: Address
  poolCategory: PoolCategory
  projectLink: string
  tokenPerBlock: string
  sortOrder?: number
  harvest?: boolean
  isFinished?: boolean
  tokenDecimals: number
}

export type Nft = {
  name: string
  description: string
  originalImage: string
  previewImage: string
  blurImage: string
  sortOrder: number
  bunnyId: number
}
