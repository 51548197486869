export default {
  pizza: {
    97: '',
    355113: '0x7EcaaCA6eB73beeBCc2595dE864410dC50567B7F',
  },
  pasta: {
    97: '',
    355113: '0x420FfF77FC4adD3478030A895A175BcD56568BF0',
  },
  masterChef: {
    97: '',
    355113: '0x30CA643734CEc96F9202931A7b2A16B7c8aC6b85',
  },
  pastaChef: {
    97: '',
    355113: '0xBAc68Fea1dbc30270704c151EDe9e137685c21E4',
  },
  wbnb: {
    97: '',
    355113: '0x7938ACd297d53bD743c3926E3C24e7262C18AEc3',
  },
  lottery: {
    97: '',
    355113: '0x48ca6C7080F9099B5C9A452E237BF5fD28a3F608',
  },
  lotteryNFT: {
    97: '',
    355113: '0x88906e736d1BD9711c59d4F3820dA4414aeb7019',
  },
  mulltiCall: {
    355113: '0x852FCcD0C50cAdCd3EbfBcb06a9373d759246Dd3',
    97: '',
  },
//   busd: {
//     355113: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
//     97: '',
//   },
//   chs: {
//     355113: '0xaDD8A06fd58761A5047426e160B2B88AD3B9D464',
//     97: '',
//   },
//   hots: {
//     355113: '0x793766efcA4CEF8c55EE950E759AD6FF73D49c09',
//     97: '',
//   },
//   kp3rb: {
//     355113: '0x5EA29eEe799aA7cC379FdE5cf370BC24f2Ea7c81',
//     97: '',
//   },
//   usdt: {
//     355113: '0x55d398326f99059fF775485246999027B3197955',
//     97: '',
//   },
//    usdc: {
//     355113: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
//     97: '',
//   },
//    dai: {
//     355113: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
//     97: '',
//   },
//   eth: {
//    355113: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
//    97: '',
//  },
//  xchs: {
//   355113: '0x9f51CAA2A1D1663fCa5bEFbFaf6C8acD8Ac9717a',
//   97: '',
// },
// cpizza: {
//  355113: '0xda3684575afa20309938434F3d40799B12868057',
//  97: '',
// },
//   pizzaRabbits: {
//     355113: '',
//     97: '',
//   },
//   ifo: {
//     355113: '0xF90Ee56bCF8411f67FD0E44776b2f2A566Ef5bC2',
//     97: '',
//   },
  tusdt: {
    355113: '0xbd9A5c1d9fBbBEC84633ec9Cb046C01fB79809f2',
    97: '',
  },
  int: {
    355113: '0xa9449C8E42D5D49a2B5031A843747B342816dC13',
    97: '',
  },
  fns: {
    355113: '0xD89E302C8ac7AbAa4eC6016961e04692cccb6039',
    97: '',
  },
  cod: {
    355113: '0xc489778CD7DB9427a730F30BD66a57762DE96628',
    97: '',
  },
  wbft: {
    355113: '0x7938ACd297d53bD743c3926E3C24e7262C18AEc3',
    97: '',
  },
  chap: {
    355113: '0x9581aa53E089F4E8f0B3c566f00121DF7c83c83B',
    97: '',
  },
}
