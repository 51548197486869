import { MenuEntry } from '@pizzafinance/ui-sdk'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://amm.testnet.chapswap.com/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://amm.testnet.chapswap.com/#/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: '/lottery',
  },
  // {
  //   label: 'IDO',
  //   icon: 'IfoIcon',
  //   href: '/ido',
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Overview',
  //       href: 'https://info.cheeseswap.app',
  //     },
  //     {
  //       label: 'Tokens',
  //       href: 'https://info.cheeseswap.app/#/token/0x7EcaaCA6eB73beeBCc2595dE864410dC50567B7F',
  //     },
  //     {
  //       label: 'Pairs',
  //       href: 'https://info.cheeseswap.app/#/pair/0x8405be915af56589756a275d4894fa9f0ff6ca0f',
  //     },
  //     {
  //       label: 'Accounts',
  //       href: 'https://info.cheeseswap.app/#/accounts',
  //     },
  //   ],
  // },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Openchat',
        href: 'https://oc.app/community/vlpdh-aiaaa-aaaar-bh3pq-cai/',
      },
      {
        label: 'Mora Planet',
        href: 'https://mora.app/planet/o4tit-iiaaa-aaaan-qdpuq-cai',
      },
    ],
  },
]

export default config
