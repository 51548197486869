import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'RCHAP',
    lpAddresses: {
      97: '',
      355113: '0x7EcaaCA6eB73beeBCc2595dE864410dC50567B7F',
    },
    tokenSymbol: 'CHOP',
    tokenAddresses: {
      97: '',
      355113: '0x420FfF77FC4adD3478030A895A175BcD56568BF0',
    },
    quoteTokenSymbol: QuoteToken.BFT,
    quoteTokenAdresses: contracts.wbft,
  },
  {
    pid: 1,
    lpSymbol: 'CHAP-BFT LP',
    lpAddresses: {
      97: '',
      355113: '0xF5f4e220B39713C47242A8530441085B8F28Fa23',
    },
    tokenSymbol: 'CHAP',
    tokenAddresses: {
      97: '',
      355113: '0x9581aa53E089F4E8f0B3c566f00121DF7c83c83B',
    },
    quoteTokenSymbol: QuoteToken.BFT,
    quoteTokenAdresses: contracts.wbft,
  },
  {
    pid: 2,
    lpSymbol: 'CHAP-tUSDT LP',
    lpAddresses: {
      97: '',
      355113: '0xdE509748A1Fd9d2d221b970a9bE2eC9cb90c4da3',
    },
    tokenSymbol: 'CHAP',
    tokenAddresses: {
      97: '',
      355113: '0x9581aa53E089F4E8f0B3c566f00121DF7c83c83B',
    },
    quoteTokenSymbol: QuoteToken.tUSDT,
    quoteTokenAdresses: contracts.tusdt,
    isCommunity: false,
  },
{
    pid: 3,
    lpSymbol: 'INT-BFT LP',
    lpAddresses: {
      97: '',
      355113: '0x4d6B502Da58feF38833869EDC128Cc4fC45CE090',
    },
    tokenSymbol: 'INT',
    tokenAddresses: {
      97: '',
      355113: '0xa9449C8E42D5D49a2B5031A843747B342816dC13',
    },
    quoteTokenSymbol: QuoteToken.BFT,
    quoteTokenAdresses: contracts.wbft,
  },
{
    pid: 4,
    lpSymbol: 'COD-BFT LP',
    lpAddresses: {
      97: '',
      355113: '0x9F3C5ed9b1A518Fe2c7C15A21B69A7152394bd45',
    },
    tokenSymbol: 'COD',
    tokenAddresses: {
      97: '',
      355113: '0xc489778CD7DB9427a730F30BD66a57762DE96628',
    },
    quoteTokenSymbol: QuoteToken.BFT,
    quoteTokenAdresses: contracts.wbft,
  },
{
   pid: 5,
   lpSymbol: 'FNS-BFT LP',
   lpAddresses: {
     97: '',
     355113: '0x58E2B0eC1C2E0bDd93EE171F0AF1E0efE654a1fd',
   },
   tokenSymbol: 'FNS',
   tokenAddresses: {
     97: '',
     355113: '0xD89E302C8ac7AbAa4eC6016961e04692cccb6039',
   },
   quoteTokenSymbol: QuoteToken.BFT,
   quoteTokenAdresses: contracts.wbft,
   isCommunity: false,
 },
{
   pid: 6,
   lpSymbol: 'tUSDT-BFT LP',
   lpAddresses: {
     97: '',
     355113: '0xac7cE1b7032391eABC55BB3438C76f9e6Fb04D43',
   },
   tokenSymbol: 'tUSDT',
   tokenAddresses: {
     97: '',
     355113: '0xbd9A5c1d9fBbBEC84633ec9Cb046C01fB79809f2',
   },
   quoteTokenSymbol: QuoteToken.BFT,
   quoteTokenAdresses: contracts.wbft,
   isCommunity: false,
 },
]

export default farms