import React from 'react'
import { Card, CardBody, Heading, Text } from '@pizzafinance/ui-sdk'
import styled from 'styled-components'
import { getBalanceNumber } from 'utils/formatBalance'
import { useTotalSupply, useBurnedBalance, useTotalSupply1 } from 'hooks/useTokenBalance'
import useI18n from 'hooks/useI18n'
import { getPizzaAddress, getchapAddress } from 'utils/addressHelpers'
import CardValue from './CardValue'

const StyledPizzaStats = styled(Card)`
  margin-left: auto;
  margin-right: auto;
`

const Row = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 8px;
`

const PizzaStats = () => {
  const TranslateString = useI18n()
  const totalSupply = useTotalSupply()
  const totalSupply1 = useTotalSupply1()
  const burnedBalance = useBurnedBalance(getPizzaAddress())
  const burnedBalance1 = useBurnedBalance(getchapAddress())
  const pizzaSupply = totalSupply ? getBalanceNumber(totalSupply) - getBalanceNumber(burnedBalance) : 0
  const chapSupply = totalSupply1 ? getBalanceNumber(totalSupply1) - getBalanceNumber(burnedBalance1) : 0
  

  return (
    <StyledPizzaStats>
      <CardBody>
        <Heading size="xl" mb="24px">
          {TranslateString(534, 'CHAP/RCHAP Stats')}
        </Heading>
        <Row>
          <Text fontSize="14px">{TranslateString(536, 'Total CHAP Supply')}</Text>
          {chapSupply && <CardValue fontSize="14px" value={chapSupply} />}
        </Row>
        <Row>
          <Text fontSize="14px">{TranslateString(536, 'Total RCHAP Supply')}</Text>
          {pizzaSupply && <CardValue fontSize="14px" value={pizzaSupply} />}
        </Row>
          <Row>
          <Text fontSize="14px">{TranslateString(538, 'Total RCHAP Burned')}</Text>
          <CardValue fontSize="14px" value={getBalanceNumber(burnedBalance)} />
        </Row>
        <Row>
          <Text fontSize="14px">{TranslateString(538, 'Total CHAP Burned')}</Text>
          <CardValue fontSize="14px" value={getBalanceNumber(burnedBalance1)} />
        </Row>
        <Row>
          <Text fontSize="14px">{TranslateString(540, 'New RCHAP/block')}</Text>
          <CardValue fontSize="14px" decimals={4} value={0.25} />
        </Row>
      </CardBody>
    </StyledPizzaStats>
  )
}

export default PizzaStats
